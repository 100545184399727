<template>
    <v-progress-linear
        color="accent"
        indeterminate
    ></v-progress-linear>
</template>
<script>
export default {
    name: 'LoadingBar',
};
</script>